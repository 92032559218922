import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subscription, concat, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class CheckVersionUpdateService {
  constructor(
    public appRef: ApplicationRef,
    private zone: NgZone,
    private translate: TranslateService,
    private modalController: ModalController,
    private alertController:AlertController
  ) {}
  currentTimeStampValue = 0;
  versionChecksubscription: Subscription;
  ngswPath: string = `${location.protocol}//${location.host}/ngsw.json`;

  async checkForVersionUpdates() {
    let timestamp = await this.getCurrentSWTimeStamp();
    this.currentTimeStampValue = timestamp;

    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );

    //Checks every two minutes and unsubscribe after first alert to user
    const everyMinute$ = interval(2 * 60 * 1000);

    const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute$);
    this.versionChecksubscription = everyMinuteOnceAppIsStable$.subscribe(
      () => {
        this.checkSWVersionUpdatesAndPrompt();
      }
    );
  }

  async showPrompt() {
    let title = '';
    let content = '';
    this.translate
      .get(['version.newVersionTitle', 'version.newVersionDesc'])
      .subscribe((values) => {
        title = values['version.newVersionTitle'];
        content = values['version.newVersionDesc'];
      });
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: title,
        message: content,
        buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel: blah');
              },
            },
            {
              text: 'Okay',
              handler: () => {
               this.reloadCurrentPage();
              },
            },
          ],
    });
    await alert.present();
  }

  async reloadCurrentPage() {
    await this.finalizeUpgrade();
    window.location.reload();
   }

  private async unregisterServiceWorkers() {
    let registrations = await navigator.serviceWorker.getRegistrations();
    for (let registration of registrations) {
    
      await registration.unregister();
    }
  }
  public async getCaches() {
    return await caches.keys();
  }

  public async getCurrentSWTimeStamp() {
    let version = localStorage.getItem('sw-version');
    let updateSW = localStorage.getItem('sw-update');
    try {
      if (!version ||JSON.parse(updateSW)) {
        // will happen in following scenarios:
        //  - Just upgraded
        //  - First time opened
        //  - cleared appcache
        // this will not cause update prompt, b/c the IDEAL STATE is if this isnt cached,
        // there wasnt a existing installed SW
        let newVersion = (await (await fetch(this.ngswPath)).json())?.timestamp;
        version = newVersion;
        localStorage.setItem('sw-version', version);
        localStorage.setItem('sw-update', 'false');
      }

    } catch (e) {
      throw e
    }

    return parseInt(version);
  }

  private cancelVersionCheck() {
    try {
      this.versionChecksubscription.unsubscribe();
    } catch {
      console.warn('[UpdateService] Unsubscribe from version check failed.');
    }
  }

  private async checkSWVersionUpdatesAndPrompt() {
    let intDate = +new Date();
    let serverTimeStampValue = 0;
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const randomNumber = array[0] / (1 << 32);
    let serverUrl = `${this.ngswPath}?ngsw-cachebust=${
      randomNumber * intDate
    }`;
    if (!this.currentTimeStampValue) {
      console.info(
        '[UpdateService] No SW timestamp discovered. Disabling version check.'
      );
      this.cancelVersionCheck();
      return;
    }

    try {
      await fetch(serverUrl)
        .then((res) => res.json())
        .then((json) => {
          serverTimeStampValue = json.timestamp;
          if (this.currentTimeStampValue != serverTimeStampValue) {
            this.initializeUpgrade();
          }
        });
    } catch (e) {
      console.error('[UpdateService] Failed to check for updates: \n', e);
    }
  }

  private async deleteCaches() {
    let names = await caches.keys();
    for (let name of names) {
      caches.delete(name);
    }
  }

  public async initializeUpgrade() {
    await this.unregisterServiceWorkers();
    // used to delete SW caches here
    this.zone.run(async () => {
      this.cancelVersionCheck();
      this.showPrompt();
    });
  }

  public async finalizeUpgrade() {
    // this needs to be run to stop the upgrade prompt from showing every
    // time the app is loaded (assuming SW was updated)

    // clear sw version and allow next load to pull latest
    localStorage.setItem('sw-update', 'true');
    await this.deleteCaches();
  }
}
