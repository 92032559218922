import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { retry, timeout } from 'rxjs/operators';
import { AppBaseEntityManager } from '../entityManager/AppBaseEntityManager';
import { environment } from './../../../src/environments/environment';
import { AuthService } from './../../../src/services/auth.service';
import { SyncConfig } from './../../../src/interface/SyncConfig';
import {
  Downloadable,
  Uploadable,
} from './../../../src/interface/SyncInterface';
import { AppBaseEntity } from '../entity/AppBaseEntity';
import { ADALProvider } from 'src/shared/adal/adal';
import { SynclogEntityManagerService } from '../entityManager/synclog-entity-manager.service';

export class AppBaseServiceProvider {
  entityManger = AppBaseEntityManager;
  protected baseURL = environment.apiEndpoint;
  serviceName = null;
  dexieName = null;
  protected downloadOperationName = null;
  protected uploadOperationName = null;
  protected currentVersion = '1.0';

  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public syncLogerEntityManager: SynclogEntityManagerService,
    public adalService: ADALProvider
  ) {}

  isDownloadable(): this is Downloadable {
    return 'download' in this;
  }

  isUploadable(): this is Uploadable {
    return 'upload' in this;
  }

  getDownloadUrl(): string {
    if (this.isDownloadable) {
      return `${this.baseURL}/${this.serviceName}/${this.downloadOperationName}`;
    } else {
      return null;
    }
  }

  getUploadUrl(): string {
    if (this.isUploadable) {
      return `${this.baseURL}/${this.serviceName}/${this.uploadOperationName}`;
    } else {
      return null;
    }
  }

  getBodyForDownload(config: SyncConfig): Promise<object> {
    return Promise.resolve({
      projectId: config.project,
      lastUpdateDate: config.lastDownloadTime,
    });
  }

  getBodyForUpload(config: SyncConfig): Promise<object> {
    return Promise.resolve({
      projectId: config.project,
      lastUpdateDate: config.lastUploadTime,
    });
  }

  getOptions(): object {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.authService.getToken())
      .set('Content-Type', 'application/json');

    return {
      headers,
    };
  }

  async post(
    url: string,
    body: any | null,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Promise<any> {
    try {
      let result = await this.http
        .post(url, body, options)
        .pipe(retry(10))
        .pipe(timeout(180000))
        .toPromise();
      if (result) {
        return result;
      } else {
        throw new Error('Request timeout');
      }
    } catch (e) {
      throw 'Unkown error Http POST';
    }
  }

  getItems(eventId: number, dateFrom: string, dateTo: string): Promise<any[]> {
    let httpParams = new HttpParams();

    if (dateFrom) httpParams = httpParams.append('DateFrom', dateFrom);
    if (dateTo) httpParams = httpParams.append('DateTo', dateTo);
    httpParams = httpParams.append('api-version', this.currentVersion);

    return new Promise<any[]>((resolve, reject) => {
      (async () => {
        this.http
          .get<any[]>(`${this.baseURL}/${this.serviceName}/${eventId}`, {
            params: httpParams,
          })
          .pipe(timeout(300000))
          .subscribe(
            (vals) => {
              const helperValues = this.getNewVals(vals);
              if (vals.length > 0) {
                this.syncLogerEntityManager
                  .createUploadSyncLogForEvent(
                    helperValues.provider,
                    eventId,
                    this.serviceName,
                    helperValues.largestValue
                  )
                  .then(() => {
                    resolve(helperValues.newVals);
                  });
              } else {
                resolve(helperValues.newVals);
              }
            },
            (error) => {
              reject(error);
            },
            () => {
              console.log('HTTP request completed.');
            }
          );
      })();
    });
  }

  getNewVals(vals: any[]): {
    largestValue: number;
    provider: string;
    newVals: any[];
  } {
    const helperValues = {
      largestValue: NaN,
      provider: '',
      newVals: [],
    };
    helperValues.newVals = vals.map((val) => {
      const newVal = new AppBaseEntity(val);
      const ud = Date.parse(newVal.UpdatedDate);
      if (helperValues.provider === '') {
        helperValues.provider = newVal['Provider']
          ? newVal['Provider']
          : 'TaPro';
      }
      if (isNaN(helperValues.largestValue) || ud > helperValues.largestValue)
        helperValues.largestValue = ud;
      return newVal;
    });
    return helperValues;
  }

  async saveItems(items: any[]): Promise<any[]> {
    try {
      const response = await this.http
        .post<any[]>(`${this.baseURL}/${this.serviceName}`, items)
        .pipe(timeout(30000))
        .toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async saveSummaryItems(items: any[]): Promise<any[]> {
    try {
      const item = items[0];
      const response = await this.http
        .post<any[]>(`${this.baseURL}/${this.serviceName}`, item)
        .pipe(timeout(30000))
        .toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  getIncrementalItems(provider: string, eventId: number): Promise<any[]> {
    return new Promise<AppBaseEntity[]>((resolve, reject) => {
      this.syncLogerEntityManager
        .getLastSyncLogsForEntitiesByEnent(provider, eventId, this.serviceName)
        .then((log) => {
          const dateFrom = log ? log.LastDownloadTime : null;
          this.getItems(eventId, dateFrom, null)
            .then((rets) => {
              resolve(rets);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
