import { Injectable } from '@angular/core';
import { ADALProvider } from './../shared/adal/adal';
import { ADALModel } from './../../src/shared/adal/adal-model';
import { User } from './../data/User';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public adToken: string;
  private rolePrivate: string;

  constructor(private adalService: ADALProvider) {}

  getToken() {
    if (this.adToken === undefined || this.adToken === null) {
      this.loginWithAD().then((adalModel) => {
        this.adToken = adalModel.Token;
      });
    } else {
      return this.adToken;
    }
  }

  logOut() {
    this.adalService.logOut().then(() => alert('Logout completed'));
  }

  async loginWithAD(): Promise<ADALModel> {
    return new Promise<ADALModel>((resolve, reject) => {
      (async () => {
        try {
          const adalModel = await this.adalService.msalLogin();
          this.adToken = adalModel.Token;
          this.saveAdToken(adalModel);
          resolve(adalModel);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  private saveAdToken(adModel: ADALModel) {
    localStorage.setItem('Token', adModel.Token);
    localStorage.setItem('ExpiresOn', adModel.ExpiresOn);
    localStorage.setItem('Name', adModel.Name);
  }

  getUser(): User {
    const firstName = localStorage.getItem('FirstName');
    const lastName = localStorage.getItem('LastName');
    const uid = localStorage.getItem('UserId');
    const cai = localStorage.getItem('CAI');
    if (firstName === null || lastName === null || uid === null) {
      return null;
    } else {
      const user: User = new User();
      user.firstName = firstName;
      user.lastName = lastName;
      user.id = parseInt(uid, 10);
      user.cai = cai;
      return user;
    }
  }

  isTokenExpired(): boolean {
    const expiresOn = parseInt(localStorage.getItem('ExpiresOn'), 10);
    let ret = true;
    if (Date.now() < expiresOn * 1000) {
      ret = false;
    }

    return ret;
  }

  get role(): string {
    return this.rolePrivate;
  }
  set role(value: string) {
    this.rolePrivate = value;
  }

  async smartLogin(): Promise<User> {
    try {
      // let adalModel: ADALModel;
      // let claimToken: string;
      // 1. check if token expired
      if (this.isTokenExpired()) {
        // get new AD token
        await this.getNewADModel();
        // login - Azure Login
        // claimToken = await this.loginWithADToken(this.adToken);
      }
      return;
    } catch (error) {
      throw error;
    }
  }

  async getNewADModel(): Promise<ADALModel> {
    try {
      const adalModel = await this.adalService.msalLogin();
      return adalModel;
    } catch (error) {
      throw error;
    }
  }
}
