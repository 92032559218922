import { Injectable } from '@angular/core';
import { UserEntityManagerService } from '../EntityManagerIndex';
import { ADALProvider } from 'src/shared/adal/adal';
import { UserDexie } from '../entity/dexie/User.dexie';

@Injectable({
  providedIn: 'root',
})
export class UserHelperService {
  constructor(
    private userEntityManager: UserEntityManagerService,
    private adalService: ADALProvider
  ) {}
  private usersMap: any = {};
  private users: UserDexie[];
  private isInited = false;

  buildUsers(force?: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.isInited && !force) {
        resolve();
      } else {
        this.userEntityManager
          .getAll()
          .then((rets) => {
            this.users = rets as UserDexie[];
            this.users.forEach((user) => {
              this.usersMap[user.Upn] = user;
            });
            this.isInited = true;
            resolve();
          })
          .catch((e) => reject(e));
      }
    });
  }
  getMyInfo(): UserDexie {
    return new UserDexie({ Upn: this.adalService.getUPN() });
  }

  getUserInfo(upn: string): Promise<UserDexie> {
    return new Promise<UserDexie>((resolve, reject) => {
      (async () => {
        try {
          const userInfo = this.usersMap ? this.usersMap[upn] : null;
          if (userInfo) {
            // get from memory
            resolve(userInfo);
          } else {
            // get from db
            this.userEntityManager.getUser(upn).then((user) => {
              this.usersMap[upn] = user;
              resolve(user);
            });
          }
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  searchUser(query: string): Promise<UserDexie[]> {
    const queryInUpperCase = query.toUpperCase();
    return new Promise<UserDexie[]>((resolve, reject) => {
      this.buildUsers()
        .then(() => {
          const filtedUsers = this.users.filter(
            (user) =>
              (user.FirstName &&
                user.FirstName.toUpperCase().indexOf(queryInUpperCase) !==
                  -1) ||
              (user.LastName &&
                user.LastName.toUpperCase().indexOf(queryInUpperCase) !== -1) ||
              (user.Cai &&
                user.Cai.toUpperCase().indexOf(queryInUpperCase) !== -1)
          );
          resolve(filtedUsers);
        })
        .catch((e) => reject(e));
    });
  }

  getUsersWithNotes(eventId: number): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      (async () => {
        this.userEntityManager
          .getUserUpnsWithNotes(eventId)
          .then((rets) => {
            resolve(rets);
          })
          .catch((e) => reject(e));
      })();
    });
  }
}
