import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ADALModel } from './adal-model';
import { ADALConfig } from './adal-config';
//import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
//import { Device } from '@ionic-native/device/ngx';
//import { AppVersion } from '@ionic-native/app-version/ngx';
import { environment } from 'src/environments/environment';

declare let Microsoft: any;

@Injectable({
  providedIn: 'root',
})
export class ADALProvider {
  private isInited: boolean = false;
  private adalConfig: ADALConfig;
  public model: any;
  private adToken: string;
  private _upn: string;
  public set upn(value: string) {
    localStorage.setItem('UPN', value);
    this._upn = value;
  }
  public get upn(): string {
    if (this._upn) {
      return this._upn;
    } else {
      return localStorage.getItem('UPN');
    }
  }
  constructor(
    private _msalService: MsalService,
    private platform: Platform //private device: Device, //private appVersion: AppVersion,
  ) //private appAvailability: AppAvailability
  {
    platform.ready().then(async () => {
      await this._init();
    });
  }

  msalLogin(force: boolean = false): Promise<ADALModel> {
    return new Promise<ADALModel>((resolve, reject) => {
      this.platform
        .ready()
        .then(() => {
          const model = new ADALModel();
          const accounts = this._msalService.instance.getAllAccounts();

          if (accounts.length > 0) {
            this._msalService.instance.setActiveAccount(accounts[0]);
          }

          this._msalService.instance.addEventCallback((event) => {
            // set active account after redirect
            if (
              event.eventType === EventType.LOGIN_SUCCESS &&
              event.payload.account
            ) {
              const account = event.payload.account;
              this._msalService.instance.setActiveAccount(account);
            }
          });

   

          // handle auth redired/do all initial setup for msal
          this._msalService.instance
            .handleRedirectPromise()
            .then((authResult) => {
              // Check if user signed in
              const account = this._msalService.instance.getActiveAccount();

              const accessTokenRequest = {
                scopes: ['openid', 'offline_access', environment.scope],
                account,
              };

              if (account != null && accessTokenRequest) {
                this._msalService
                  .acquireTokenSilent(accessTokenRequest)
                  .subscribe({
                    next: (AR) => {
                      model.Token = AR.accessToken;
                      this.adToken = AR.accessToken;
                      model.ExpiresOn = AR.expiresOn.getTime().toString();
                      model.Name = AR.account.name;
                      this.upn = AR.account.username;
                      model.ADObject = AR;
                      resolve(model);
                    },
                  });
              }

              if (!account) {
                // redirect anonymous user to login page
                this._msalService.instance.loginRedirect();
              }
            })
            .catch((err) => {
              // TODO: Handle errors
              console.log(err);
            });

        })
        .catch((e) => reject('Unkown Exception while SSO, ' + e));
    });
  }

  private _init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      (async () => {
        if (!this.isInited) {
          this.isInited = true;
        }
        resolve();
      })();
    });
  }

  private _buildIMSALUser(
    msalResponse: IMSALResponse | AuthenticationResult
  ): ADALModel {
    const model = new ADALModel();
    if (msalResponse['token']) {
      msalResponse = msalResponse as IMSALResponse;
      this.upn = msalResponse.account.username;
      this.adToken = msalResponse.token;
      model.Name = msalResponse.account.claims.find((c) => c.key === 'name')
        ?.value as string;
      model.Token = msalResponse.token;
      model.Email = msalResponse.account.username;
      model.ExpiresOn = msalResponse.account.ExpiresOn;
      model.ADObject = msalResponse;
      return model;
    } else {
      msalResponse = msalResponse as AuthenticationResult;
      this.upn = msalResponse.account.username;
      this.adToken = msalResponse.accessToken;
      model.Name = msalResponse.account.name;
      model.Email = msalResponse.account.username;
      model.Token = msalResponse.accessToken;
      model.ExpiresOn = msalResponse.expiresOn.toTimeString();
      model.ADObject = msalResponse;
      return model;
    }
  }

  public async adalLogout(): Promise<void> {
    try {
      await this.platform.ready();
      await this._msalService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } catch (error) {
      throw new Error('Unknown Exception while SSO Logout, ' + error);
    }
  }

  clearTokenCache2() {
    try {
      this._msalService.logout();
    } catch (error) {}
  }

  logOut(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        this._msalService.logoutPopup({
          mainWindowRedirectUri: '/',
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
  getUPN(): string {
    return this._upn;
  }
  getToken(): string {
    return this.adToken;
  }
}

export interface IMSALResponse {
  token: string;
  account: {
    id: string;
    username: string;
    ADObject: any;
    ExpiresOn: string;
    claims: Array<{
      key:
        | 'name'
        | 'preferred_username'
        | 'nbg'
        | 'exp'
        | 'aio'
        | 'uti'
        | 'iss'
        | 'kid'
        | 'sub'
        | 'ver'
        | 'aud'
        | 'oid'
        | 'alg'
        | 'typ'
        | 'iat'
        | 'rh'
        | 'tid';
      value: string | number;
    }>;
  };
}
