import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

import { AppPlatform } from 'src/data/InternalTypes';
import { LoadingService } from 'src/services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { CheckVersionUpdateService } from 'src/services/check-version-update.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  ngOnInit(): void {
    this.initializeApp();
    
  }
  constructor(
    private platform: Platform,

    private loadingService: LoadingService,
    private translate: TranslateService,
    private update: CheckVersionUpdateService
  ) {this.update.checkForVersionUpdates();}

  initializeApp() {
    this.platform.ready().then(() => {
   
      this.translate.addLangs(['en', 'ru', 'kaz']);
      this.translate.setDefaultLang('en');

      const lang = localStorage.getItem('language');

      if (lang) {
        this.translate.use(lang);
      } else {
        this.translate.use('en');
      }
    });
  }
}
